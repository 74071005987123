<template>
  <v-container>
    <v-row class="text-center mt-2">
      <v-col class="d-none d-md-flex">
      </v-col>
      <v-col>
        <v-img
          :src="require('../assets/LogoLeoLearning.png')"
          class="mb-5"
          contain
          height="200"
        />
        <v-card class="card" elevation="2">
          <v-form v-on:submit.prevent="submit()" ref="form">
            <div class="mx-10">
              <p class="pt-6 text-h6">
                CONNEXION ADMINISTRATEUR
              </p>
              <v-text-field type="text" label="Nom d'utilisateur" placeholder="Nom d'utilisateur" :rules="usernameRules" v-model="payload.username"></v-text-field>
              <v-text-field type="password" label="Mot de passe" placeholder="Mot de passe" :rules="passwordRules" v-model="payload.password"></v-text-field>
              <v-btn
                elevation="2"
                type="submit"
                color="primary"
                class="my-5"
              >Connexion</v-btn>
            </div>
          </v-form>
        </v-card>
      </v-col>
      <v-col class="d-none d-md-flex">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'LoginPage',
  props: {
  },
  data: () => ({
    payload: {
      username: '',
      password: ''
    },
    usernameRules: [
      v => !!v || "Le nom d'utilisateur est requis"
    ],
    passwordRules: [
      v => !!v || "Le mot de passe est requis"
    ],
  }),
  methods: {
    submit(){
      if(this.$refs.form.validate()){
        this.$http.post('https://api-recrutement.leolearning.fr/auth', this.payload).then(response => {
          if(response.status === 200 && 'token' in response.data){
            if(!this.$session.exists()){
              this.$session.start()
            }
            this.$session.set('jwt', response.data.token)
            this.$router.push('/dashboard')
          }
        }).catch(err => {
          console.log(err.response)
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>