<template>
  <login-page />
</template>

<script>
import LoginPage from '../components/LoginPage.vue'

  export default {
    name: 'Inscription',

    components: {
      LoginPage
    },
  }
</script>
